<template>
    <base-section id="about-our-services">
        <base-section-heading icon="mdi-home-city" title="About our Services" />

        <v-container>
            <v-row>
                <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
                    <base-info-card v-bind="card" />
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: "SectionAboutOurProduct",

    data: () => ({
        cards: [
            {
                title: "Tenant Screening",
                subtitle: "World Class Technology Platform",
                text:
                    "Apply Oregon is powered by Pacific Screening, Inc a <strong>minority owned screening company</strong> with 30 years in the Oregon market.  We have built strong relationships with the property manager, private landlords and vendors in our rental housing industry.  We have been a fast growing company among non-profit organizations. Pacific Screening has a reputation for excellent customer service both to the landlord and the applicant.",
                callout: "01",
            },
            {
                title: "Property Manager Partnerships",
                subtitle: "Serving 5K+ Property Managers",
                text:
                    "We have worked hard to form lasting partnerships with property managers, private landlords and community non-profit programs.  Our mission is to help provide quality tenant screening for landlords and access to housing for families and individuals.  We maintain strict standards of legal compliance, adhering to rental criteria and facilitate the cooperation between landlords and applicants.",
                callout: "02",
            },
            {
                title: "Strategic Partnerships",
                subtitle: "Rent Well",
                text:
                    "The partnership between Rent Well and their organization of multiple non-profits has been in place for 10 years.  We have worked together to assist applicants to discover their barriers that are preventing them from renting a home.  Working with applicants to discover their barriers and direction to to remove those barriers is the heart of Rent Well. When those barriers are removed, applicants can confidently apply and secure housing.",
                callout: "03",
            },
        ],
    }),
};
</script>
